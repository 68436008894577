import { createSlice } from "@reduxjs/toolkit";
export const loginStatus = createSlice({
  name: "loginStatus",
  initialState: {
    value:
      localStorage.getItem("expirationTime") &&
      Number.isFinite(Number(localStorage.getItem("expirationTime"))),
  },
  reducers: {
    changeLoginStatus: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { changeLoginStatus } = loginStatus.actions;
export default loginStatus.reducer;
