import React, { useState, useEffect, useMemo, lazy, Suspense } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Home from "./pages/home";
import Welcome from "./pages/welcome";
import Talk from "./pages/talk";
import AppRouter from "./pages/app-router";
import Share from "./pages/share";
import NotFound from "./pages/not-found";
import { CircularProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { IntlProvider } from "react-intl";
import zhCNLocal from "@/locale/zh-CN.json";
import enLocal from "@/locale/en.json";
import { saveToken, languageSetting } from "@/api/project";

function App() {
  const navigate = useNavigate();
  const deviceLanguage = useSelector((state) => state.language.value);
  const [userLang, setUserLang] = useState(enLocal);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const langMessages = {
    "zh-cn": zhCNLocal,
    en: enLocal,
  };
  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    const topicKeys = localStorage.getItem("topicKeys");
    if (localStorage.getItem("userUUID") == null) {
      const newUUID = uuidv4();
      localStorage.setItem("userUUID", newUUID);
    }

    languageSetting({ category: deviceLanguage })
      .then((res) => {
        if (res.isSuccess) {
          // localStorage.setItem("language", res.data);
          setUserLang(JSON.parse(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
        setUserLang(langMessages[deviceLanguage]);
      });
  }, []);
  return (
    <IntlProvider locale={deviceLanguage} messages={userLang}>
      <Routes>
        <Route
          index
          element={
            <Suspense fallback={<CircularProgress />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/welcome"
          element={
            <Suspense fallback={<CircularProgress />}>
              <Welcome />
            </Suspense>
          }
        />
        <Route
          path="/talk"
          element={
            <Suspense fallback={<CircularProgress />}>
              <Talk />
            </Suspense>
          }
        />
        <Route
          path="home/*"
          element={
            <Suspense fallback={<CircularProgress />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/app-router"
          element={
            <Suspense fallback={<CircularProgress />}>
              <AppRouter />
            </Suspense>
          }
        />
        <Route
          path="/share"
          element={
            <Suspense fallback={<CircularProgress />}>
              <Share />
            </Suspense>
          }
        />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </IntlProvider>
  );
}

export default App;
