import React, { useEffect, useMemo, useState, memo } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Badge, TabBar, ActionSheet, Toast } from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import "./style.less";
// import { CustomMeta } from "@/components";
import { Box, Paper } from "@mui/material";
import Routes from "../../route/homeRoutes";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import NoiseAwareIcon from "@mui/icons-material/NoiseAware";
import VoiceChatIcon from "@mui/icons-material/VoiceChat";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { getHistoricalChatRecords } from "@/api/project";
import roastBgImg from "../../assets/imgs/roast_bg.png";
import { FormattedMessage } from "react-intl";
import { firebaseConfig } from "@/utils/firebaseConfig";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  OAuthProvider,
} from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { changeLoginStatus } from "@/store/features/loginStatus";
import googleIcon from "../../assets/icons/google.svg";
import appleIcon from "../../assets/icons/apple.svg";
const Home = () => {
  const appName = initializeApp(firebaseConfig);
  const auth = getAuth(appName);
  const provider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider("apple.com");
  const [hasMark, setHasMark] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const ref = React.useRef(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dayjs = require("dayjs");
  const loginStatus = useSelector((state) => state.loginStatus.value);
  const dispatch = useDispatch();
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const tabs = [
    {
      key: "/home/default",
      icon: <HomeOutlinedIcon sx={{ fontSize: "30px !important" }} />,
    },
    {
      key: "/home/wordware",
      icon: (
        <LocalFireDepartmentIcon
          sx={{ fontSize: "30px !important", color: "red" }}
        />
      ),
    },
    {
      key: "/home/my",
      icon: <PersonOutlineOutlinedIcon sx={{ fontSize: "30px !important" }} />,
    },
    {
      key: "/home/history",
      icon: <HistoryOutlinedIcon sx={{ fontSize: "30px !important" }} />,
      badge: hasMark ? Badge.dot : "",
    },
  ];
  useEffect(() => {
    if (
      localStorage.getItem("userInfo") == null ||
      localStorage.getItem("userInfo") == undefined
    ) {
      localStorage.setItem("userInfo", JSON.stringify({ age: "1", sex: "1" }));
    }
    if (
      localStorage.getItem("topicKeys") == null ||
      localStorage.getItem("topicKeys") == undefined
    ) {
      localStorage.setItem("topicKeys", "");
    }
    if (
      localStorage.getItem("expirationTime") &&
      Number.isFinite(Number(localStorage.getItem("expirationTime")))
    ) {
      getHistoricalChatRecords().then((res) => {
        if (res != null && res.isSuccess) {
          let arr = [];
          res.data.article_ids.forEach((element) => {
            let obj = res.data[element];
            obj.article_id = element;
            arr.push(obj);
          });
          arr = arr.filter((item) => {
            return item.mark == 0;
          });
          setHasMark(arr.length > 0);
        }
      });
    }
  }, []);
  useEffect(() => {
    if (pathname != "/home/default") {
      ref.current.scrollIntoView({ block: "start" });
    }
    console.log("pathname", pathname);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log("用户已登录", user);
        if (
          localStorage.getItem("expirationTime") &&
          Number.isFinite(Number(localStorage.getItem("expirationTime")))
        ) {
          dispatch(changeLoginStatus(true));
          const date1 = Number(localStorage.getItem("expirationTime"));
          const date2 = dayjs().valueOf();
          const difference = date1 - date2;
          // console.log(difference);
          if (difference <= 60000 * 15) {
            console.log("两个时间戳相隔十五分钟以内");
            user.auth.currentUser?.getIdTokenResult(true).then((res) => {
              localStorage.setItem("token", res.token);
              localStorage.setItem(
                "expirationTime",
                dayjs(res.expirationTime).valueOf()
              );
            });
          }
        } else {
          // console.log("用户数据已清理，请重新登录");
          dispatch(changeLoginStatus(false));
        }
      } else {
        // console.log("用户未登录");
        localStorage.removeItem("expirationTime");
        localStorage.removeItem("token");
        dispatch(changeLoginStatus(false));
      }
    });
  }, [pathname]);
  const routeActive = (value) => {
    let domRoot = document.getElementById("root");
    if (value == "/home/my") {
      domRoot.style.background = "#ffffff";
    } else if (value == "/home/wordware") {
      domRoot.style.background = `url(${roastBgImg})`;
      domRoot.style.backgroundSize = "cover";
      domRoot.style.backgroundRepeat = "no-repeat";
    } else {
      domRoot.style.background = "#f8f8f8";
    }
    navigate(value);
  };
  const actions = [
    {
      text: (
        <div>
          <img src={googleIcon} alt="" className="actionsheet-icon" />
          <FormattedMessage id="Message_register_google" />
        </div>
      ),
      key: "google",
      onClick: () => thirdPartyLogin("google"),
    },
    {
      text: (
        <div>
          <img src={appleIcon} alt="" className="actionsheet-icon" />
          <FormattedMessage id="Message_register_appleid" />
        </div>
      ),
      key: "apple",
      onClick: () => thirdPartyLogin("apple"),
    },
  ];
  const loginBtn = () => {
    setVisible1(true);
  };
  const thirdPartyLogin = (loginType) => {
    let newProvider;
    let userType;
    if (loginType == "google") {
      newProvider = provider;
      userType = 1;
    } else if (loginType == "apple") {
      newProvider = appleProvider;
      userType = 2;
    }
    signInWithPopup(auth, newProvider)
      .then((result) => {
        dispatch(changeLoginStatus(true));
        const user = result.user;
        localStorage.setItem("token", user.accessToken);
        localStorage.setItem(
          "expirationTime",
          user.stsTokenManager.expirationTime
        );
        Toast.show({
          content: "Login successful",
        });
        setVisible1(false);
        verifyToken({
          token: user.accessToken,
          user_type: userType,
          user_channels: localStorage.getItem("topicKeys").split(","),
          user_info: {
            age: Number(JSON.parse(localStorage.getItem("userInfo"))["age"]),
            sex: Number(JSON.parse(localStorage.getItem("userInfo"))["sex"]),
          },
          device_id: localStorage.getItem("userUUID") || "",
        });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // 如果弹出窗口被阻止，则重新尝试
        if (errorCode === "auth/popup-blocked") {
          alert("请允许弹出窗口，然后重试。");
        }
      });
  };
  const openInAppBtn = () => {
    // airen://lexi/main
    // airen://lexi/news?article_id=xxx
    // https://apps.apple.com/us/app/lexi-ai-smart-news-assistant/id6621190005
    // https://play.google.com/store/apps/details?id=com.airen.lexi
    const appScheme = "airen://lexi/main";
    const appDetailScheme = `airen://lexi/news?article_id=${pathname
      .split("/")
      .at(-1)}`;
    const appStoreUrl =
      "https://apps.apple.com/us/app/lexi-ai-smart-news-assistant/id6621190005";
    const googlePlayUrl =
      "https://play.google.com/store/apps/details?id=com.airen.lexi";
    let deviceType = "";
    if (/android/i.test(userAgent)) {
      deviceType = "android";
    } else if (
      /iPad|iPhone|iPod|Macintosh|Mac OS X/i.test(userAgent) &&
      !window.MSStream
    ) {
      deviceType = "ios";
    } else {
      deviceType = "unknown";
    }
    // 创建一个隐藏的 iframe 用于尝试打开应用
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = pathname.includes("home_detail") ? appDetailScheme : appScheme;
    // 将 iframe 添加到 DOM 中
    document.body.appendChild(iframe);
    // 设置一个定时器，检查用户是否安装了应用
    setTimeout(() => {
      // 如果在指定时间内没有打开应用，则重定向到 App Store
      window.location.href = deviceType == "ios" ? appStoreUrl : googlePlayUrl;
    }, 1000);
  };

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <div className="open-in-app-fixed">
        <div className="btn-wrap">
          {loginStatus ? (
            <div></div>
          ) : (
            <div className="sign-in-wrap" onClick={loginBtn}>
              <span className="sign-in">
                <FormattedMessage id="Message_register_tip_2" />
              </span>
              <RightOutline className="sign-in-icon" />
            </div>
          )}
          <div className="open-btn" onClick={openInAppBtn}>
            <FormattedMessage id="Home_openapp" />
          </div>
        </div>
      </div>
      <div className="open-in-app" id="open-in-app"></div>
      <Routes />
      <Paper
        sx={{
          position: "fixed",
          bottom: -2,
          left: 0,
          right: 0,
          height: "60px",
        }}
        elevation={3}
        className="nav-wrap"
      >
        <TabBar
          activeKey={pathname}
          defaultActiveKey={"/home/default"}
          onChange={routeActive}
        >
          {tabs.map((item) => (
            <TabBar.Item
              key={item.key}
              icon={item.icon}
              title={item.title}
              badge={item.badge}
            />
          ))}
        </TabBar>
      </Paper>
      <ActionSheet
        extra={<FormattedMessage id="Message_register_tip_2" />}
        visible={visible1}
        actions={actions}
        onClose={() => setVisible1(false)}
      />
      {/* {pathname.includes("home_detail") ? null : (
        <CustomMeta
          title="Lexi AI- Smart News Assistant"
          description="Stay informed, stay ahead"
          imageUrl="https://airen-news-ai.s3.ap-southeast-1.amazonaws.com/default_file/logo.png"
          url="https://lexi.run/"
          type="website"
        />
      )} */}
    </Box>
  );
};
export default Home;
