/* 
接口 api
*/
import axios from "./axios";
const baseUrl = "/api";
const baseUrlV1 = "/v1";

// 获取评论员列表
export const getAiCommentator = (params) => {
  return axios.get(`${baseUrl}/news/getAiCommentator`, params);
};
// 获取评论员的评论记录
export const getAiCommentLog = (params) => {
  return axios.get(`${baseUrl}/news/getAiCommentLog`, params);
};
// 根据用户ID获取订阅话题列表
export const getSubscribeSetting = (params) => {
  return axios.get(`${baseUrl}/news/getSubscribeSetting`, params);
};
//保存用户订阅话题
export const saveSubscribeSetting = (params) => {
  return axios.post(`${baseUrl}/news/saveSubscribeSetting`, params);
};
//保存用户token
export const saveToken = (params) => {
  return axios.post(`${baseUrl}/token`, params);
};
// 获取用户ID获取订阅话题列表
// export const getNewsList = (params, options) => {
//   return axios.get(`${baseUrl}/news/getNewsList`, params, options);
// };
export const getNewsList = (params) => {
  return axios.post(`${baseUrlV1}/article/articles`, params);
};
// 获取文章详情
// export const getNewsDetail = (params) => {
//   return axios.get(`${baseUrl}/news/getNewsDetail`, params);
// };
export const getNewsDetail = (params) => {
  return axios.get(`${baseUrlV1}/article/detail`, params);
};
// 获取历史聊天记录
export const getHistoricalChatRecords = (params) => {
  return axios.get(`${baseUrl}/news/getHistoricalChatRecords`, params);
};
// 更新聊天记录为已读
export const endingChat = (params) => {
  return axios.get(`${baseUrl}/news/ending_chat`, params);
};
// 根据用户ID和文章ID获取聊天信息
export const chatLog = (params) => {
  return axios.get(`${baseUrl}/news/chat-log`, params);
};
// 获取语言配置
export const languageSetting = (params) => {
  return axios.get(`${baseUrl}/news/languageSetting`, params);
};
//保存三方登录token
export const verifyToken = (params) => {
  return axios.post(`${baseUrl}/verify-token`, params);
};
//保存用户反馈内容
export const saveFeedback = (params) => {
  return axios.post(`${baseUrl}/news/saveFeedback`, params);
};
// 获取吐槽对象列表
// export const getNewsRoast = (params) => {
//   return axios.get(`${baseUrl}/news/getNewsRoast`, params);
// };
export const getNewsRoast = (params) => {
  return axios.get(`${baseUrlV1}/roast/news-roast`, params);
};
// 获取吐槽对象被吐槽记录
// export const getNewsRoastLog = (params) => {
//   return axios.get(`${baseUrl}/news/getNewsRoastLog`, params);
// };
export const getNewsRoastLog = (params) => {
  return axios.get(`${baseUrlV1}/roast/user-roast-log`, params);
};
//保存用户吐槽和AI吐槽内容
// export const saveUserRoast = (params) => {
//   return axios.post(`${baseUrl}/news/saveUserRoast`, params);
// };/v1/roast/user-roast
export const saveUserRoast = (params) => {
  return axios.post(`${baseUrlV1}/roast/user-roast`, params);
};
// 获取吐槽页新消息数量
export const getNewCount = (params) => {
  return axios.get(`${baseUrlV1}/roast/new-count`, params);
};
// 根据文章和聊天内容生成提示问题
export const chatInspire = (params) => {
  return axios.post(`${baseUrlV1}/chat/inspire`, params);
};
// 获取用户信息
export const getUserInfo = (params) => {
  return axios.get(`${baseUrlV1}/user/user-info`, params);
};
// 获取bookmark
export const getBookmark = (id) => {
  return axios.get(`${baseUrlV1}/bookmark/${id}`);
};
// 获取question
export const getQuestionInfo = (id) => {
  return axios.get(`${baseUrlV1}/question_info/${id}`);
};
