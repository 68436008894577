// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-router-page .logo {
  width: 100px;
  display: block;
  margin: 100px auto 25px;
  border-radius: 12px;
}
.app-router-page p {
  text-align: center;
  font-size: 20px;
}
.app-router-page .download {
  width: fit-content;
  display: flex;
  margin: 50px auto;
}
.app-router-page .download div {
  width: 30px;
}
.app-router-page .download img {
  width: 150px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/app-router/index.less"],"names":[],"mappings":"AAAA;EAEI,YAAA;EACA,cAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;AALA;EAQI,kBAAA;EACA,eAAA;AAAJ;AATA;EAYI,kBAAA;EACA,aAAA;EACA,iBAAA;AAAJ;AAdA;EAgBM,WAAA;AACN;AAjBA;EAmBM,YAAA;EACA,eAAA;AACN","sourcesContent":[".app-router-page {\n  .logo {\n    width: 100px;\n    display: block;\n    margin: 100px auto 25px;\n    border-radius: 12px;\n  }\n  p {\n    text-align: center;\n    font-size: 20px;\n  }\n  .download {\n    width: fit-content;\n    display: flex;\n    margin: 50px auto;\n    div {\n      width: 30px;\n    }\n    img {\n      width: 150px;\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
