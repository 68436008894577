// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found {
  height: calc(100vh - 150px);
  background: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
}
.not-found .text {
  margin-top: 155px;
  font-size: 20px;
  color: #8d8d8d;
}
`, "",{"version":3,"sources":["webpack://./src/pages/not-found/index.less"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AANA;EAOI,iBAAA;EACA,eAAA;EACA,cAAA;AAEJ","sourcesContent":[".not-found {\n  height: calc(100vh - 150px);\n  background: #ececec;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  .text {\n    margin-top: 155px;\n    font-size: 20px;\n    color: #8d8d8d;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
