import React, { useEffect } from "react";
import "./index.less";
import logoImg from "../../assets/imgs/user2.png";
import appleImg from "../../assets/imgs/appleDownload.png";
import googleImg from "../../assets/imgs/googleDownload.png";

function Index() {
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const param = urlParams.get("uri");
    console.log("param:", param);
    if (queryString !== "" && param !== null) {
      window.location.href = param;
    }
  }, []);
  const appleClick = () => {
    window.location.href =
      "https://apps.apple.com/us/app/lexi-ai-smart-news-assistant/id6621190005";
  };
  const googleClick = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.airen.lexi";
  };
  return (
    <div className="app-router-page">
      <img src={logoImg} alt="" className="logo" />
      <p>Lexi AI- Smart News Assistant</p>
      <div className="download">
        <img src={appleImg} alt="" className="apple" onClick={appleClick} />
        <div></div>
        <img src={googleImg} alt="" className="google" onClick={googleClick} />
      </div>
    </div>
  );
}

export default Index;
