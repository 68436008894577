import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import "./index.less";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Selector, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import StarIcon from "../../assets/icons/star.svg";
// import CircleOutlineIcon from "../../assets/icons/circle_outline.svg";
// import CheckCircleIcon from "../../assets/icons/check_circle.svg";
import CheckCircleIcon from "../../assets/icons/checked1.png";
import CircleOutlineIcon from "../../assets/icons/checked2.png";
import { FormattedMessage } from "react-intl";
import { getSubscribeSetting, saveSubscribeSetting } from "@/api/project";

const Index = () => {
  const [step, setStep] = useState("gif1"); //gif1 introduce2 chooseTopic3 generateContent4
  const [topicKeys, setTopicKeys] = useState([]);
  const [ageVal, setAgeVal] = useState("1");
  const [genderVal, setGenderVal] = useState("1");
  const [subscribeData, setSubscribeData] = useState({});
  const navigate = useNavigate();
  const { state } = useLocation();
  const isLogin =
    localStorage.getItem("expirationTime") &&
    Number.isFinite(Number(localStorage.getItem("expirationTime")));
  const getData = async () => {
    const res = await getSubscribeSetting();
    if (res.isSuccess) {
      res.data?.config.age.enum.forEach((ele) => {
        ele.value = String(ele.id);
        ele.label = ele.age;
      });
      res.data?.config.sex.enum.forEach((ele) => {
        ele.value = String(ele.id);
        ele.label = ele.sex;
      });
      setAgeVal(
        localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo"))["age"]
          : "1"
      );
      setGenderVal(
        localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo"))["sex"]
          : "1"
      );
      // if (isLogin) {
      //   res.data?.channels.forEach((chEle) => {
      //     res.data?.user_channels.forEach((ele) => {
      //       if (ele == chEle.channel_id) {
      //         chEle.isSelect = true;
      //       }
      //     });
      //   });
      //   // setAgeVal(res.data?.user?.age != -1 ? String(res.data?.user?.age) : "1");
      //   // setGenderVal(
      //   //   res.data?.user?.sex != -1 ? String(res.data?.user?.sex) : "1"
      //   // );
      //   setTopicKeys(res.data.user_channels);
      // } else {
      if (localStorage.getItem("topicKeys")) {
        // setTopicKeys(localStorage.getItem("topicKeys").split(","));
        let newArr = [];
        res.data?.channels.forEach((chEle) => {
          localStorage
            .getItem("topicKeys")
            .split(",")
            .forEach((ele) => {
              if (ele == chEle.channel_id) {
                chEle.isSelect = true;
                newArr.push(ele);
              }
            });
        });
        setTopicKeys([...newArr]);
      }
      // }
      setSubscribeData(res.data);
    }
    console.log(res);
  };
  useEffect(() => {
    getData();
    console.log(state);
    if (state !== null && state.params == "changeTopic") {
      setStep("chooseTopic3");
    } else {
      setTimeout(() => {
        setStep("introduce2");
      }, 2000);
    }
  }, []);
  useEffect(() => {
    if (step == "generateContent4") {
      setTimeout(() => {
        navigate("/home/default");
      }, 2000);
    }
  }, [step]);
  const startBtn = () => {
    setStep("chooseTopic3");
  };
  const generateBtn = () => {
    const userData = {
      user_info: { age: Number(ageVal), sex: Number(genderVal) },
      settingIds: topicKeys,
    };
    if (isLogin) {
      saveSubscribeSetting(userData).then((res) => {
        console.log(res);
        if (res.isSuccess) {
          setStep("generateContent4");
        } else {
          Toast.show({
            icon: "fail",
            content: "Save failed",
          });
        }
      });
    } else {
      setStep("generateContent4");
      localStorage.setItem(
        "userInfo",
        JSON.stringify({ age: ageVal, sex: genderVal })
      );
    }
    localStorage.setItem(
      "topicKeys",
      topicKeys.sort((a, b) => a - b)
    );
    sessionStorage.setItem(
      "capsuleTabIndex",
      topicKeys.length > 0 ? topicKeys.sort((a, b) => a - b)[0] : ""
    );
  };
  const onAgeChange = (val) => {
    if (val.length > 0) {
      setAgeVal(val[0]);
    }
  };
  const onGenderChange = (val) => {
    if (val.length > 0) {
      setGenderVal(val[0]);
    }
  };

  return (
    <div className="welcome-wrap">
      {step == "gif1" ? (
        <div className="gif1">
          <div className="img-wrap">
            <img
              src={require("../../assets/imgs/user.png")}
              alt=""
              style={{ width: "100px" }}
            />
            <img
              src={require("../../assets/imgs/lexi1.png")}
              alt=""
              style={{ width: "60px", margin: "15px 20px" }}
            />
          </div>
        </div>
      ) : step == "introduce2" ? (
        <div className="introduce2">
          <div className="img">
            <img
              src={require("../../assets/imgs/user.png")}
              alt=""
              style={{ width: "100px" }}
            />
          </div>
          <ul className="text-wrap">
            {/* <li>你好！👋 很高兴认识你！</li>
            <li>我是你的专属新闻官xx，</li>
            <li>我将和你一起🍃</li>
            <li>探索新闻背后的故事，</li>
            <li>发现不一样的视角，</li>
            <li>让思想不在随波逐流。</li> */}
            {/* <li>Hello！👋 Nice to meet you！👋 </li>
            <li>I'm AI news journalist Lexi，</li>
            <li>🍃 exploring the stories behind the news，</li>
            <li>🔍 discovering unique perspectives，</li>
            <li>💭 making thoughts don't simply go with the flow.</li> */}
            <li>
              <FormattedMessage id="Onboard_Intro_1" />
            </li>
            <li>
              <FormattedMessage id="Onboard_Intro_2" />
            </li>
            <li>
              <FormattedMessage id="Onboard_Intro_3" />
            </li>
          </ul>
          <div className="btn-wrap">
            <Button
              variant="contained"
              size="small"
              className="btn"
              onClick={startBtn}
            >
              <FormattedMessage id="Onboard_Intro_start" />
            </Button>
          </div>
        </div>
      ) : step == "chooseTopic3" ? (
        <div className="chooseTopic3">
          <div className="content-wrap">
            <div className="hi-wrap">
              <img
                src={require("../../assets/imgs/user.png")}
                alt=""
                className="img"
              />
              <p className="hi">
                <FormattedMessage id="Onboard_UserInfo_tip_1" />
              </p>
            </div>
            <div style={{ padding: "0 16px" }}>
              {state !== null && state.params == "changeTopic" ? (
                <></>
              ) : (
                <>
                  <p className="introduce">
                    <FormattedMessage id="Onboard_UserInfo_tip_2" />
                  </p>
                  <div className="age-gender-wrap">
                    <div className="age">
                      <p className="p1">
                        <FormattedMessage id="Onboard_UserInfo_age" />
                      </p>
                      <Selector
                        style={{
                          "--border-radius": "100px",
                          "--checked-border":
                            "solid var(--adm-color-primary) 1px",
                          "--padding": "8px 24px",
                          "--checked-border": "solid #0CC8F0 1px",
                          "--checked-text-color": "#0CC8F0",
                          "--padding": "5px 0px",
                          "--border-radius": "14px",
                          "--border": "1px solid #ffffff",
                        }}
                        showCheckMark={false}
                        options={subscribeData.config?.age?.enum}
                        columns={1}
                        onChange={onAgeChange}
                        value={ageVal}
                      />
                    </div>
                    <div className="age gender">
                      <p className="p1">
                        <FormattedMessage id="Onboard_UserInfo_sex" />
                      </p>
                      <Selector
                        style={{
                          "--border-radius": "100px",
                          "--checked-border":
                            "solid var(--adm-color-primary) 1px",
                          "--padding": "8px 24px",
                          "--checked-border": "solid #18C18C 1px",
                          "--checked-text-color": "#18C18C",
                          "--padding": "5px 0px",
                          "--border-radius": "14px",
                          "--border": "1px solid #ffffff",
                        }}
                        showCheckMark={false}
                        options={subscribeData.config?.sex?.enum}
                        columns={1}
                        onChange={onGenderChange}
                        value={genderVal}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="choose-text-wrap">
                <img src={StarIcon} alt="" className="img1" />
                <p className="p1">
                  <FormattedMessage id="Onboard_UserInfo_chooseinter" />
                </p>
                <img src={StarIcon} alt="" className="img2" />
              </div>
              <div className="topic-wrap">
                {subscribeData.channels?.map((item, index) => {
                  return (
                    <div
                      className="one"
                      style={{
                        // backgroundImage: `url(${item.channel_icon})`,
                        background: item.isSelect ? "#E2FDFF" : "#E1EEEF",
                        // backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                      key={index}
                      onClick={() => {
                        let newKeys = [...topicKeys];
                        if (newKeys.includes(item.channel_id)) {
                          newKeys = newKeys.filter(
                            (filterItem) => filterItem !== item.channel_id
                          );
                        } else {
                          newKeys.push(item.channel_id);
                        }
                        setTopicKeys([...newKeys]);
                        item.isSelect = !item.isSelect;
                      }}
                    >
                      {item.isSelect ? (
                        <img src={CheckCircleIcon} alt="" className="check" />
                      ) : (
                        <img src={CircleOutlineIcon} alt="" className="check" />
                      )}
                      <p className="title">{item.channel_name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="btn-contain">
            <Button
              variant="contained"
              size="small"
              className="btn"
              onClick={generateBtn}
            >
              {state !== null && state.params == "changeTopic" ? (
                <FormattedMessage id="Onboard_UserInfo_generatenews" />
              ) : topicKeys.length > 0 ? (
                <FormattedMessage id="Onboard_UserInfo_generatenews" />
              ) : (
                <FormattedMessage id="Onboard_UserInfo_skip" />
              )}
            </Button>
          </div>
          <div style={{ height: "150px" }}></div>
        </div>
      ) : (
        <div className="generateContent4">
          <img
            src={require("../../assets/imgs/user.png")}
            alt=""
            style={{ width: "100px" }}
          />
          <p className="text">
            <FormattedMessage id="Onboard_UserInfo_generatingtip" />
          </p>
          <Box sx={{ width: "100%" }} className="LinearProgress-wrap">
            <LinearProgress color="success" />
          </Box>
          <p className="text-bottom">
            <FormattedMessage id="Onboard_UserInfo_desai" />
          </p>
        </div>
      )}
    </div>
  );
};

export default Index;
