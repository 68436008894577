// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share-page {
  margin: 35px 26px;
}
.share-page .title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #222222;
  line-height: 23px;
  margin-bottom: 12px;
}
.share-page .footer {
  font-family: PingFangSC, PingFang SC;
  font-size: 12px;
  color: #999999;
  line-height: 16px;
  margin-top: 24px;
}
.share-page .no-data-wrap {
  text-align: center;
}
.share-page .no-data-wrap img {
  width: 160px;
  height: 150px;
  display: block;
  margin: 80px auto 20px;
}
.share-page .no-data-wrap p {
  font-family: PingFangSC, PingFang SC;
  font-size: 16px;
  color: #222222;
  line-height: 22px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/share/index.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAFA;EAGI,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;AAEJ;AAVA;EAWI,oCAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AAEJ;AAjBA;EAkBI,kBAAA;AAEJ;AApBA;EAoBM,YAAA;EACA,aAAA;EACA,cAAA;EACA,sBAAA;AAGN;AA1BA;EA0BM,oCAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;AAGN","sourcesContent":[".share-page {\n  margin: 35px 26px;\n  .title {\n    font-family: PingFangSC, PingFang SC;\n    font-weight: 600;\n    font-size: 20px;\n    color: #222222;\n    line-height: 23px;\n    margin-bottom: 12px;\n  }\n  .footer {\n    font-family: PingFangSC, PingFang SC;\n    font-size: 12px;\n    color: #999999;\n    line-height: 16px;\n    margin-top: 24px;\n  }\n  .no-data-wrap {\n    text-align: center;\n    img {\n      width: 160px;\n      height: 150px;\n      display: block;\n      margin: 80px auto 20px;\n    }\n    p {\n      font-family: PingFangSC, PingFang SC;\n      font-size: 16px;\n      color: #222222;\n      line-height: 22px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
