import React, { useState, useEffect } from "react";
import "./index.less";
import ReactMarkdown from "react-markdown";
import noDataImg from "../../assets/imgs/no_data1.png";
import { getBookmark, getQuestionInfo } from "@/api/project";
import { Swiper } from "antd-mobile";

function Index() {
  const [title, setTitle] = useState("");
  const [markdownContent, setMarkdownContent] = useState("");
  const [undisplayedImages, setUndisplayedImages] = useState([]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramType = urlParams.get("type");
    const paramId = urlParams.get("id");
    if (
      queryString !== "" &&
      paramType !== null &&
      paramId !== null &&
      paramType !== "" &&
      paramId !== ""
    ) {
      if (paramType == "bookmark") {
        getBookmark(paramId).then((res) => {
          console.log("res:", res);
          setTitle(res.data.title);
          setMarkdownContent(res.data.content);
          setUndisplayedImages(res.data.undisplayedImages);
        });
      } else if (paramType == "question") {
        getQuestionInfo(paramId).then((res) => {
          console.log("res:", res);
          setTitle(res.data.title);
          setMarkdownContent(res.data.content);
          setUndisplayedImages(res.data.undisplayedImages);
        });
      }
    }
  }, []);

  const CustomUl = ({ node, inline, ...props }) => (
    <ul style={{ listStyleType: "square" }} {...props} />
  );
  const CustomLi = ({ node, inline, ...props }) => (
    <li style={{ margin: "12px 0", fontSize: "14px" }} {...props} />
  );
  const CustomP = ({ node, inline, ...props }) => (
    <p style={{ fontSize: "14px" }} {...props} />
  );
  const CustomImg = ({ node, inline, ...props }) => (
    <img
      style={{ width: "100%", borderRadius: "12px", margin: "12px 0" }}
      {...props}
    />
  );
  const items = undisplayedImages.map((item, index) => (
    <Swiper.Item key={index}>
      <img
        src={item}
        alt=""
        style={{
          width: "100%",
          height: "200px",
          borderRadius: "12px",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
    </Swiper.Item>
  ));
  return (
    <div className="share-page">
      {markdownContent !== "" ? (
        <>
          <p className="title">{title}</p>
          <ReactMarkdown
            components={{
              ul: CustomUl,
              li: CustomLi,
              p: CustomP,
              img: CustomImg,
            }}
          >
            {markdownContent}
          </ReactMarkdown>
          <Swiper style={{ marginTop: "20px" }}>{items}</Swiper>
          <p className="footer">以上内容由AI收集并生成</p>
        </>
      ) : (
        <div className="no-data-wrap">
          <img src={noDataImg} alt="" />
          <p>
            {navigator.language.toUpperCase().toLowerCase().includes("zh")
              ? "文章内容已无法查看"
              : "Content unavailable"}
          </p>
        </div>
      )}
    </div>
  );
}

export default Index;
