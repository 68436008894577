import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { CircularProgress } from "@mui/material";
const Default = lazy(() => import("../pages/default"));
const My = lazy(() => import("../pages/my"));
const History = lazy(() => import("../pages/history"));
const HomeDetail = lazy(() => import("../pages/home-detail"));
const Setting = lazy(() => import("../pages/setting"));
const PrivacyAgreement = lazy(() => import("../pages/privacy-agreement"));
const UserAgreement = lazy(() => import("../pages/user-agreement"));
const Feedback = lazy(() => import("../pages/feedback"));
const AboutUs = lazy(() => import("../pages/about-us"));
const Wordware = lazy(() => import("../pages/wordware"));
const NotFound = lazy(() => import("../pages/not-found"));
const routeList = [
  {
    key: "/",
    name: "首页",
    path: "/",
    component: Default,
  },
  {
    key: "default",
    name: "首页",
    path: "/default",
    component: Default,
  },
  {
    key: "my",
    name: "我的",
    path: "/my",
    component: My,
  },
  {
    key: "history",
    name: "历史记录",
    path: "/history",
    component: History,
  },
  {
    key: "home_detail",
    name: "首页详情页",
    path: "/home_detail/:articleId",
    component: HomeDetail,
  },
  {
    key: "setting",
    name: "设置页",
    path: "/setting",
    component: Setting,
  },
  {
    key: "setting",
    name: "隐私协议页",
    path: "/setting/privacy_agreement",
    component: PrivacyAgreement,
  },
  {
    key: "setting",
    name: "用户协议页",
    path: "/setting/user_agreement",
    component: UserAgreement,
  },
  {
    key: "feedback",
    name: "联系页",
    path: "/setting/feedback",
    component: Feedback,
  },
  {
    key: "about_us",
    name: "关于我们页",
    path: "/setting/about_us",
    component: AboutUs,
  },
  {
    key: "wordware",
    name: "吐槽页",
    path: "/wordware",
    component: Wordware,
  },
];
function homeRoutes() {
  return (
    <div>
      <Routes>
        <Route
          index
          element={
            <Suspense fallback={<CircularProgress />}>
              <Default />
            </Suspense>
          }
        ></Route>
        {routeList.map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              element={
                <Suspense fallback={<CircularProgress />}>
                  <item.component />
                </Suspense>
              }
            ></Route>
          );
        })}
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </div>
  );
}
export default homeRoutes;
