import React, { useState, useEffect, useRef } from "react";
import { NavBar } from "antd-mobile";
import { Avatar } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.less";
import OutlinedInput from "@mui/material/OutlinedInput";
import TelegramIcon from "@mui/icons-material/Telegram";
import sendIcon from "../../assets/icons/send.svg";
import chatTipIcon from "../../assets/icons/chat_tip.png";
import { v4 as uuidv4 } from "uuid";
import { endingChat, chatLog, chatInspire } from "@/api/project";
const Index = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [firstChatObj, setFirstChatObj] = useState({});
  const [btnDiasbled, setBtnDiasbled] = useState(false);
  const [insprireArr, setInsprireArr] = useState([]);
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const { state } = useLocation();
  const dayjs = require("dayjs");
  const backBtn = () => {
    if (state !== null && state.hasOwnProperty("touchIndex")) {
      navigate(state.preRoute, {
        state: {
          touchIndex: state.touchIndex,
        },
      });
    } else {
      navigate(-1);
    }
  };
  const sendMessage = async () => {
    setBtnDiasbled(true);
    try {
      if (message.trim()) {
        setMessages((messages) => [
          ...messages,
          {
            text: message,
            user: true,
            time: dayjs().format("HH:mm:ss YYYY.MM.DD"),
          },
          {
            text: "",
            ai: true,
            time: dayjs().format("HH:mm:ss YYYY.MM.DD"),
          },
        ]);
        setMessage("");
        const url = `${process.env.REACT_APP_URL}v1/chat/generate`;
        const data = {
          // message: [],
          // message_id: messageId,
          articleId: firstChatObj.article_id,
          publisherId: firstChatObj.publisher_id,
          // content: firstChatObj.content,
          chatTime: new Date().getTime(),
          question: message,
        };
        try {
          // await endingChat({ message_id: messageId });
          getChatInspireData();
          const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
              token: localStorage.getItem("token"),
            },
            // signal: controller.signal,
          });
          if (response.body) {
            const reader = response.body.getReader();
            let accumulatedData = "";
            while (true) {
              const { done, value } = await reader.read();
              if (done) {
                break;
              }
              const chunkText = new TextDecoder().decode(value);
              // console.log(chunkText.split("\n"));
              chunkText.split("\n").forEach((item) => {
                if (item !== "") {
                  accumulatedData += JSON.parse(item).content;
                  setMessages((messages) => {
                    const lastMessage = messages[messages.length - 1];
                    return [
                      ...messages.slice(0, -1),
                      {
                        ...lastMessage,
                        text: accumulatedData,
                      },
                    ];
                  });
                }
              });
            }
            setBtnDiasbled(false);
          }
        } catch (error) {
          console.error("Error fetching stream:", error);
          throw error;
        }
        // const response = await fetchStreamData();
        // if (response && response.length !== undefined) {
        //   console.log(response.length);
        //   let currentText = "";
        //   let charIndex = 0;
        //   const simulateTyping = () => {
        //     if (charIndex < response.length) {
        //       currentText += response[charIndex];
        //       setMessages((messages) => {
        //         const lastMessage = messages[messages.length - 1];
        //         return [
        //           ...messages.slice(0, -1),
        //           {
        //             ...lastMessage,
        //             text: currentText,
        //           },
        //         ];
        //       });
        //       charIndex++;
        //       requestAnimationFrame(simulateTyping);
        //     }
        //   };
        //   simulateTyping();
        //   setBtnDiasbled(false);
        // } else {
        //   console.error(
        //     "Response is undefined or does not have a length property"
        //   );
        //   setBtnDiasbled(false);
        // }
      }
    } catch (error) {
      console.error(error);
      throw error;
      // setMessage("Error sending message. Try again later.");
    }
  };
  // const controller = new AbortController();
  // function abortGeneral(buttonId) {
  //   controller.abort();
  //   // buttonHide("button1-1");
  //   console.log("generalRequest！");
  // }
  const getChatInspireData = () => {
    chatInspire({ articleId: state.article_id }).then((res) => {
      setInsprireArr(res.data);
    });
  };
  const handleInspire = (val) => {
    setMessage(val);
  };
  useEffect(() => {
    chatLog({
      article_id: state.article_id,
    }).then((res) => {
      console.log(res);
      if (res.isSuccess) {
        setFirstChatObj(res.data.first_chat);
        if (
          res.data.messages.length > 0 &&
          res.data.messages[res.data.messages.length - 1].mark == 0
        ) {
          endingChat({
            message_id:
              res.data.messages[res.data.messages.length - 1].message_id,
          });
        }
        res.data.messages.forEach((element) => {
          setMessages((messages) => [
            ...messages,
            {
              text: element.question,
              user: true,
              time: dayjs(element.chat_time).format("HH:mm:ss YYYY.MM.DD"),
            },
            {
              text: element.answer,
              ai: true,
              time: dayjs(element.chat_time).format("HH:mm:ss YYYY.MM.DD"),
            },
          ]);
        });
      }
    });
    getChatInspireData();
  }, []);
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  return (
    <div className="talk-page">
      <NavBar onBack={backBtn} style={{ background: "#ffffff" }}>
        <span className="navbar-title">{firstChatObj.publisher_name}</span>
      </NavBar>
      <div className="chat-part">
        <div className="ai-wrap">
          <div style={{ display: "flex" }}>
            <Avatar
              alt=""
              src={firstChatObj.publisher_avatar}
              sx={{
                width: 36,
                height: 36,
                border: "1px solid #cccccc",
                marginRight: "10px",
              }}
            />
            <div className="content">
              <p className="title">
                It seems you are interested in this 👇topic👇, something more to
                talk with me?
              </p>
              <div className="img-text">
                <img src={firstChatObj.cover_image} alt="" />
                <span>{firstChatObj.title}</span>
              </div>
            </div>
          </div>
          <p className="time">
            {dayjs.unix(firstChatObj.creat_time).format("HH:mm:ss YYYY.MM.DD")}
          </p>
        </div>
        {messages.map((message, index) => (
          <>
            {message.ai ? (
              <div className="ai-wrap" key={"ai" + index}>
                <div style={{ display: "flex" }}>
                  <Avatar
                    alt=""
                    src={firstChatObj.publisher_avatar}
                    sx={{
                      width: 36,
                      height: 36,
                      border: "1px solid #cccccc",
                      marginRight: "10px",
                    }}
                  />
                  <div className="content-text">{message.text}</div>
                </div>
                <p className="time">{message.time}</p>
              </div>
            ) : (
              <div className="own-wrap" key={"own" + index}>
                <div></div>
                <div>
                  <div className="content">{message.text}</div>
                  <p className="time">{message.time}</p>
                </div>
              </div>
            )}
          </>
        ))}
        {insprireArr.map((item) => {
          return btnDiasbled ? null : (
            <div className="inspire-wrap">
              <img src={chatTipIcon} alt="" />
              <div className="inspire" onClick={() => handleInspire(item)}>
                {item}
              </div>
            </div>
          );
        })}

        <div ref={messagesEndRef} />
      </div>
      <div className="input-wrap">
        <input
          type="text"
          value={message}
          placeholder="Add comment…"
          onChange={(e) => setMessage(e.target.value)}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <TelegramIcon
            style={{
              background: "#000000",
              borderRadius: "50%",
              color: "#ffffff",
            }}
            onClick={sendMessage}
          /> */}
          <img
            src={sendIcon}
            alt=""
            style={{
              background: "#000000",
              borderRadius: "50%",
              color: "#ffffff",
            }}
            onClick={btnDiasbled ? () => {} : sendMessage}
          />
        </div>
      </div>
      {/* <button onClick={abortGeneral}>btn</button> */}
    </div>
  );
};

export default Index;
